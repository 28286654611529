:root {
  --primary: #20b2aa;
  --secondary: #b83b5e;
  --danger: #e61515;
  --dark-grey: #333;
  --medium-grey: #777;
  --light-grey: #f5f5f5;
  --secondary-light-grey: #aaa;
  /* CURRENT */
  --themeColor: #20b2aa;
  /* SHADES */

  --themeColorShade1: #e4faf9;
  --themeColorShade2: #aef0ed;
  --themeColorShade3: #78e7e1;
  --themeColorShade4: #42ddd4;
  --themeColorShade5: #22bdb5;
  --themeColorShade6: #188781;
  --themeColorShade7: #0f514d;
  --themeColorShade8: #0f514d;
  --themeColorShade9: #051b1a;

  --transparentThemeColor: rgba(32, 178, 171, 0.3);
  --themeBluishGrey: #3b5275;
  --themeDarkGrey: #343a40;
  --white: #fff;
  --lightGrey: #f8f8fa;
  --secondaryLightGrey: #efefef;
  --lightGrey2: rgb(239, 239, 244);
  --tertiaryLightGrey: #ccc;
  --mediumGrey: #888;
  --errorTextColor: #a30000;
  --errorBgColor: #ea77772e;
  --lossTextColor: #ea7777;
  --completeTransparent: rgba(0, 0, 0, 0);
  /* Z-INDEX */
  --z-index-99: 99;

  /*  */
  --delft-blue: #2b3a67ff;
  --saffron: #e6c229ff;
  --pumpkin: #f17105ff;
  --cardinal: #d11149ff;
  --electric-indigo: #6610f2ff;
  --tufts-blue: #1a8fe3ff;
  --icterine: #fffd82ff;
  --atomic-tangerine: #ff9b71ff;
  --red-crayola: #e84855ff;
  --brown-sugar: #b56b45ff;
  --midnight-green: #19535fff;
  --skobeloff: #0b7a75ff;
  --dun: #d7c9aaff;
  --falu-red: #7b2d26ff;
  --antiflash-white: #f0f3f5ff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* DATE PICKER STYLES */

.react-datepicker {
  font-size: 1rem !important;
  color: var(--themeBluishGrey);
}

.react-datepicker__header {
  background-color: var(--lightGrey) !important;
}

.react-datepicker__year-read-view {
  font-size: 1.4rem;
  color: var(--themeBluishGrey);
}

.react-datepicker__current-month {
  font-size: 1.4rem !important;
  margin-bottom: 1rem;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 1.4rem !important;
  font-size: 1.4rem;
}

.react-datepicker__year-option {
  font-size: 1.4rem !important;
}

.react-datepicker__input-container input {
  padding: 1.6rem;
  width: 100%;
  border: none;
  border-radius: 2px;
  border: 1px solid #ccc;
  font-size: 1.6rem;
  text-align: center;
}

.react-datepicker__input-container input:focus {
  outline: 1px solid var(--themeColor);
}

.react-datepicker__day--selected {
  background-color: var(--themeColorShade5) !important;
}
/* TOOLTIP */

.MuiTooltip-tooltipArrow {
  font-size: 1.2rem !important;
  padding: 6px !important;
}

.MuiTooltip-tooltip {
  font-size: 1.2rem !important;
  padding: 6px !important;
}
.MuiButtonBase-root.MuiRadio-root {
  padding-left: 0;
}
